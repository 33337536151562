import React from 'react';
import './Clients.css';
import fleetLogo from './logofleet.png';
import solecoLogo from './soleco.png';
import lackProtect from './lack-protect.png';
import fizjowroclaw from './fizjowroclaw.png';
import britishAccent from './british-accent-logo.svg';
import stykala from './stykala-logo.svg';
import koronowska from './koronowska.svg';

const clientLogos = [fleetLogo, solecoLogo, lackProtect, fizjowroclaw, britishAccent, stykala, koronowska]

class Clients extends React.Component {
  render (){
    return (
      <section className={'container ' + this.props.className}>
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-md-5 col-lg-4">
            <h2 className="clients__title">Zaufali nam.</h2>
            <p className="clients__description">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p>
          </div>
          <div className="col-12 col-md-7">
            <div className="row">
              {clientLogos.map((item, key)=><div className="col-6" key={key}>
                <div className="client__logo-wrapper">
                  <img src={item} alt='Fleet Meetings logo' className="client__logo"/>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Clients;