import React from 'react';
import './Footer.css';

class Footer extends React.Component {
  render(){
    return (
      <footer className={this.props.className}>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Footer</h1>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;