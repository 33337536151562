import React from 'react';
import { ReactComponent as Logo } from'./headsup.svg';
import './Header.css';


class Header extends React.Component{
  constructor(props){
    super(props);
    this.state = {loaded: false};
  }
  render(){
    return (
      <section className={this.props.className}>
        <div className="container">
          <div className="row justify-content-between">
            <Logo className="logo col-12 col-md-3 align-self-center"></Logo>
            <Nav className="col-12 col-md-9 align-self-center"></Nav>
          </div>
        </div>
      </section>
    )
  }
}

class Nav extends React.Component {
  render(){
    return (
      <nav className={this.props.className + " nav-main"}>
        <ul className="main-nav__list">
          <li>Home</li>
          <li>Kontakt</li>
          <li className="btn btn-primary">Get in touch</li>
        </ul>
      </nav>
    )
  }
}

export default Header;