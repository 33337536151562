import React from 'react';
import './Testimonials.css';
import {ReactComponent as Quotes} from './quotes.svg';
import {ReactComponent as LeftArrow} from './iconfinder_Arrow-Left_392526.svg';
import {ReactComponent as RightArrow} from './iconfinder_Arrow-Right_392525.svg';
import dw from './dw.jpg';
import kt from './kt.jpg';
import ad from './ad.jpg';

const referrials = [
  {
    title: 'Pełen profecjonalizm.',
    content: 'Firma "Heads Up!" to profesjonalny wykonawca, który potrafi zrealizować ciekawe i funkcjonalne rozwiązania internetowe.',
    recommender: 'Fleet Meetings sp. z o.o., Wrocław',
    image: dw
  },
  {
    title: 'Jesteśmy bardzo zadowoleni.',
    content: 'Zespół Heads Up! dba o terminowość i dobry kontakt z klientem. Potencjalne problemy traktuje jak wyzwanie, przez co jest w stanie zrealizować nawet najbardziej nieszablonowe zamówienie.',
    recommender: 'FDK.NET.PL, Warszawa',
    image: kt
  },
  {
    title: 'Polecamy współpracę.',
    content: 'Fachowość, ciekawe, nowoczesne pomysły, a także zaangażowanie w realizowane przedsięwzięcie i terminowość to wizytówka Heads Up!',
    recommender: 'Poradnia Pociecha, Białystok',
    image: ad
  }
]

class Testimonials extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentItem: 0
    }
  }
  isActive(side){
    const refLength = referrials.length;
    switch (side)
    {
      case 'prev':
        if (this.state.currentItem===0){
          this.setState({
            currentItem: refLength -1
          });
        }
        else {
          this.setState({
            currentItem: this.state.currentItem -1
          });
        }
        break;
      case 'next':
        if (this.state.currentItem===refLength - 1){
          this.setState({
            currentItem: 0
          })
        }
        else {
          this.setState({
            currentItem: this.state.currentItem + 1
        
          })
        }
        break;
      default:
    }
  }
  render(){
    return (
      <section className={this.props.className}>
        <div className="container-fluid">
          <div className="row">
            <div className="col justify-self-end">
              <div className="testimonials__images">
                {referrials.map((item,key)=><img src={item.image} alt='' key={key} className={'testimonil__image ' + (this.state.currentItem===key?'active':'')}/>)}
              </div>
            </div>
            <div className="col move-bottom">
              <Quotes className="testimonials__quotes"/>
              {referrials.map((item, key)=><TestimonialItem key={key} title={item.title} content={item.content} recommender={item.recommender} className={this.state.currentItem===key?'active':''} ></TestimonialItem>)}
              <div className="testimonials__nav">
                <LeftArrow onClick = {() => this.isActive('prev')}/>
                <RightArrow onClick = {() => this.isActive('next')}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

function TestimonialItem(props){
  return (
    <article className={"testimonial__body " + props.className} onMouseEnter={props.onMouseEnter}>
      <div className="testimonial__title">{props.title}</div>
      <div className="testimonial__description">{props.content}</div>
      <div className="testimonial__person">{props.recommender}</div>
    </article>
  )
}

export default Testimonials;