import React from 'react';
import './BrowserIcon.css';

class BrowserIcon extends React.Component{
  render(){
    return (
      <div className={this.props.className}>
        <div className="browserIcon__controls">
          <span className="browserIcon__control"></span>
          <span className="browserIcon__control"></span>
          <span className="browserIcon__control"></span>
          <span className="browserIcon__control browserIcon__control--bar"></span>
        </div>
        <div className="browserIcon__body">
          <div className="browserIcon__section">
            <span className="browserIcon__article-img"></span>
            <span className="browserIcon__article-content">
              <span className="browserIcon__article-row"></span>
              <span className="browserIcon__article-row"></span>
              <span className="browserIcon__article-row"></span>
            </span>
          </div>
          <div className="browserIcon__section browserIcon__section--inplace">
            <span className="browserIcon__article-img"></span>
            <span className="browserIcon__article-content">
              <span className="browserIcon__article-row"></span>
              <span className="browserIcon__article-row"></span>
              <span className="browserIcon__article-row"></span>
            </span></div>
        </div>
      </div>
    )
  }
}
export default BrowserIcon;