import React from 'react';
import './Cta.css';
import Button from '../ui/button';

class CTA extends React.Component {
  render (){
    return (
      <section className={'container ' + this.props.className}>
        <div className="cta__wrapper">
          <div className="row cta__content align-items-center">
            <div className="col-5">
              <h2 className="cta__title">{this.props.title}</h2>
            </div>
            <div className="col-4">
              <p className="cta__contact">{this.props.contact}</p>
            </div>
            <div className="col text--right">
              <Button className={''} linkHref={this.props.linkHref} linkTitle={this.props.linkTitle}></Button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CTA;