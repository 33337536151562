import React from 'react';
import './Process.css';
import {ReactComponent as Mac} from './iconfinder_ArtificialIntelligence6_2890570.svg';
import {ReactComponent as Brain} from './iconfinder_ArtificialIntelligence22_2890588.svg';
import {ReactComponent as Eye} from './iconfinder_ArtificialIntelligence26_2890587.svg';

const processSteps = [
  {
    image: Eye,
    name: 'Finding',
    description: 'Neque porro quisquam est, qui dolorem ipsum quia dol sit amet, consectetur, velit, sed quia. non numquam.'
  },
  {
    image: Brain,
    name: 'New Idea',
    description: 'Neque porro quisquam est, qui dolorem ipsum quia dol sit amet, consectetur, velit, sed quia. non numquam.'
  },
  {
    image: Mac,
    name: 'Solution',
    description: 'Neque porro quisquam est, qui dolorem ipsum quia dol sit amet, consectetur, velit, sed quia. non numquam.'
  }
]

function ProcessSteps (props){
  return(
    <div className="col-12 col-md-3">
      <div className="wp__image"><props.image/></div>
      <h3 className="wp__heading">{props.name}</h3>
      <p className="wp__content">{props.description}</p>
    </div>
  )
}
class WorkingProcess extends React.Component {
  render(){
    return (
      <section className={'container ' + this.props.className}>
        <div className="row wp__section-details">
          <h2 className="col-12 wp__title">working Process</h2>
          <div className="col-12 wp__subtitle">Problems . Idea . Solution .</div>
        </div>
        <div className="row justify-content-between">
          {processSteps.map((item, key) => <ProcessSteps key={key} image={item.image} name={item.name} description={item.description}></ProcessSteps>)}
        </div>
      </section>
    )
  }
}

export default WorkingProcess;