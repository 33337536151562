import React from 'react';
import './HeroSection.css';
import BrowserIcon from '../browser-icon/BrowserIcon';
import { ReactComponent as Logo } from'../header/headsup.svg';

class HeroSection extends React.Component {

  render (){
    return(
      <section className={this.props.className + " container"}>
        <div className="row">
          <div className="col-12 col-md-5 align-self-center">
            <h1 className="hero__title">We Make it <br/>Perfec for you.</h1>
            <div className="hero__subtitle">Simple Clean Creative</div>
          </div>
          <div className="col-12 col-md-7 align-self-end">
            <div className="hero__logo">
              <Logo className="hero__logo-svg"></Logo>
            </div>
          </div>
        </div>
        <BrowserIcon className="browserIcon"></BrowserIcon>
        <BrowserIcon className="browserIcon browserIcon--moved"></BrowserIcon>
      </section>
    )
  }
}

export default HeroSection;