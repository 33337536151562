import React from 'react';
import './Services.css';
import {ReactComponent as Plan} from './undraw_master_plan_95wa.svg';
import {ReactComponent as Cms} from './undraw_operating_system_4lr6.svg';
import {ReactComponent as Security} from './undraw_Security_on_ff2u.svg';

const services = [
  {
    title: 'Responsive Web Design',
    description: 'Strony przystosowane do działania na urządzeniach mobilnych',
    image: Plan
  },
  {
    title: 'Systemy CMS',
    description: 'Wordpress, Drupal, Adobe Experience Manager - możliwość samodzielnego zarządzania treścią',
    image: Cms
  },
  {
    title: 'Bezpieczeństwo',
    description: 'Zabezpieczenie witryny i wskazówki dotyczące bezpieczeństwa, instalacja certyfikatu SSL',
    image: Security
  }
];
class Services extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      serviceActive: 0
    }
  }
  isActive(key){
    this.setState({
      serviceActive: key
    });
  }
  render(){
    return (
      <section className={this.props.className + " container"}>
        <div className="row justify-content-between align-items-center" >
          <div className="col-12 col-lg-5">
            {services.map((item,key)=> <ServiceItem key={key} hover={this.state.serviceActive===key?true:false} title={item.title} description={item.description} onMouseEnter = {() => this.isActive(key)}></ServiceItem>)}
          </div>
          <div className="col-12 col-lg-6">
            {services.map((item, key)=> <item.image key={key} className={"services__ilustration " + (this.state.serviceActive===key?'active':'')} />)}
          </div>
        </div>
      </section>
    )
  }
}
class ServiceItem extends React.Component{
  constructor(props){
    super(props);
    const isActive = props.hover;
    this.state = {
      hover: isActive
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.hover !== prevProps.hover) {
      this.setState({
        hover: !this.state.hover
      })
    }
  }
  render(){
    return (
      <div className={"service__item " + (this.state.hover?'active':'')}  onMouseEnter={this.props.onMouseEnter}>
        <h2 className="service__title">
          {this.props.title}
        </h2>
        <div className="service__description">
          {this.props.description}
        </div>
      </div>
    );
  }
}
export default Services;

