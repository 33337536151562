import React from 'react';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './App.css';
import Header from './components/header/Header';
import HeroSection from './components/hero/HeroSection';
import Services from './components/services/Services';
import WorkingProcess from './components/process/Process';
import Testimonials from './components/testimonials/Testimonials';
import Clients from './components/clients/Clients';
import CTA from './components/cta/Cta';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div className="App">
      <Header className="app-header"></Header>
      <HeroSection className="section section__hero"></HeroSection>
      <Services className="section section__services"></Services>
      <WorkingProcess className="section section__process"></WorkingProcess>
      <Testimonials className="section section__testimonials"></Testimonials>
      <Clients className="section section__clients"></Clients>
      <CTA className="section section__cta section__cta--move-below" title="Porozmawiajmy o Twoim  projekcie." contact={<a href='mailto:kontakt@stronyinternetowe.it'>kontakt@stronyinternetowe.it</a>} linkTitle="Napisz do mnie" linkHref="mailto:kontakt@stronyinternetowe.it"></CTA>
      <Footer className="section section__footer"></Footer>
    </div>
  );
}

export default App;
